.validation{
    color: rgb(247, 0, 0);
}
.buyerDetails-head{
    text-decoration: underline;
}
.buyer-details-label{
    font-weight: bold;
    font-size: 14px;
}
.coversation-text{
    border: 1px solid lightgray;
    width: 90%;
    min-height: 35px;
}

.remarkField .remarkFieldLabel {
    position: absolute;
    top: 3.5ex;
    z-index: 1;
    left: 2em;
    background-color: #F0F2F5;
    padding: 0 5px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .remarkField > input:focus{
    background-color: #F0F2F5;
  }

  .remarkField legend{
    position: absolute;
    top: 0.5ex;
    z-index: 1;
    left: 2em;
    background-color: #F0F2F5;
    padding: 0 5px;
    font-size: 14px;
    max-width: max-content;
  }

  .remarkField input:focus + .remarkFieldLabel {
    position: absolute;
    top: 0.5ex;
    z-index: 1;
    left: 2em;
    background-color: #F0F2F5;
    padding: 0 5px;
  }

.remarkField > input{
    background-color: #F0F2F5;
    color: #000;
    border-color: #ccc;
}

.remarkField .submitNote{
    position: absolute;
    top: 3.4ex;
    z-index: 1;
    right: 2em;
    padding: 0 5px;
}

.CancleField .cancelBtn{
    position: absolute;
    top: 0.8ex;
    z-index: 1;
    right: 1.8em;
    padding: 0 5px;
}

.userLetter {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #3498db;
    color: #fff;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.return-buyer{
    font-size: 16px;
    margin-left: 17px;
    margin-bottom: 20px;
}
.returnbar{
    border-bottom: none;
}
.coversation-text::placeholder{
    padding: 8px;
    align-items: center;
}

.remarksDetails{
    list-style-type:none;
    margin-bottom: 0;
}
.remarksDetails span{
    padding-right: 10px;
    margin-top: 10px;
    font-weight: 400;
    float: left;
    font-size: 12px;
}

.css-1cm5myo{
    width: auto !important;
    height: auto !important;
    max-height: 95vh !important;
    object-fit: cover !important;
    overflow: hidden !important;
    object-position: center center !important;
    max-width: 100% !important;
    user-select: none !important;
}

.spanLink{
    color:blue;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}
.delete-txt-btn{
    color:#ff0000;
    font-weight: bold;
    cursor: pointer;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
}


.strike {
    margin-top: 20px;
    display: block !important;
    text-align: center !important;
    overflow: hidden !important;
    white-space: nowrap !important;
}
.strike > span {
    font-size: small;
    position: relative !important;
    display: inline-block !important;
    margin-top: 20px;
    margin-bottom: 10px;
}
.strike > span:before,
.strike > span:after {
    content: "";
    position: absolute !important;
    top: 50% !important;
    width: 9999px !important;
    height: 1px !important;
    background: #e6e4e4;
}
.strike > span:before {
    right: 100% !important;
    margin-right: 15px !important;
}
.strike > span:after {
    left: 100% !important;
    margin-left: 15px !important;
}

.vertical.menu  .title{
    padding-left:2px
}
.vertical.menu  .subtitle{
    padding-left:2px
}

.vertical.menu .active .title{
    color: #1575ec !important;
}

.success-lebel-rounded{
    color:rgb(18, 111, 18);
    background-color:rgb(181, 198, 181);
    padding:2px 5px 2px 5px;
    border-radius: 5px;
}

.danger-lebel-rounded{
    color:rgb(121, 15, 15);
    background-color:rgb(241, 183, 171);
    padding:2px 5px 2px 5px;
    border-radius: 5px;
}

.requiredFiledBorder{
    border-color: #ff0000;
}

.required{
    color: #ff0000;
}

.errorMessage{
  color: #ff0000;
}

input[type="date"].date::-webkit-calendar-picker-indicator {
    background: transparent;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0 !important;
    position: absolute;
    right: 0 !important;
    width: auto;
}
.fileBrowse{
    border: 1px solid;
    color: #768192;
    background-color: #fff;
    border-color: #d8dbe0;
    border-radius: 0.25rem;
    padding: 3.3px;
    width: 100%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.verticlenavIcon{
  display: inherit;
    font-size:20px;
    background-color:#1575ec;
    height:40px;
    width:40px;
    border-radius:50%;
    padding:8px;
    color:#fff;
    text-align:center
}
.verticlenavIcon i.icon{
    margin-right: 0px !important;
}
.ui.grid>*{
    padding-right: 0px !important;
}
.ui.grid .stretched{
    padding-left: 0px !important;
}
.rdrDateRangePickerWrapper{
    display: flex !important;
}

.image-previews {
	width: 100%;
	height: 100px;
	border: 2px dashed #dcdee3;
	margin: 3px;
	position: relative;
}
.image-upload-container .image {
	width: 100%;
	height: 100%;
	z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  user-select: none;
  opacity: 0.3;
  cursor: pointer;
}

.image-upload-container .image:hover {
  background-color:lightgray;
}

.react-datepicker-wrapper{
    width: 100% !important;
}

.step-doing{
    border-style: solid;
    border-bottom-style: none;
    border-width: 0.5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-color: darkgray;
}

.viewLink{
    color: #1575ec;
    cursor: pointer;
}

.viewLink:hover{
    text-decoration: underline;
}

.clearFilterBtn{
    background-color: #fff;
    color: #000;
}

.readOnly{
    background-color: #d8dbe045 !important;
}
.rangePickerWidth{
    width: 180px;
}

.react-datepicker__day--keyboard-selected{
    background-color: #216ba5 !important;
    color: #fff !important;
}

.side-nav-toggle-btn{
  display: none;
}


@media (max-width: 576px){
  .createReturn .modal-dialog {
      max-width: 100% !important;
      margin: auto !important;
  }
  .side-nav-toggle-btn{
    display: block;
  }

}

@media (max-width: 767px){
  .side-nav-toggle-btn{
    display: block;
  }
  }

@media (min-width: 992px){
.createReturn .modal-lg, .modal-xl {
    max-width: 100%;
}
.sidebar-ss{
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  padding-top: 10px;
  transition: 0.3s;
  z-index: 1000
 }

}

.dropdown-container {
    position: relative;
    display: inline-block;
  }

  .dropdown-button {
    padding: 8px 10px;
    border: none;
    cursor: pointer;
    border-radius: 0.25rem;
    font-size: smaller;
    font-weight: 500;
    border-style: solid;
    border-width: thin;
  }

  .dropdown-button.info{
    background-color: #3498db;
    color:#fff;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }

  .dropdown-container:hover .dropdown-content {
    display: block;
  }

  .bg-placed{
    background-color: #1575ec;
  }
  .bg-confirmed{
    background-color: #718504;
  }
  .bg-packaged{
    background-color: #7615ec;
  }
  .bg-dispatched{
    background-color: #ec9d15;
  }
  .bg-delivered{
    background-color: #114511;
  }
  .bg-canceled{
    background-color: #ff0000;
  }
  .bg-rto{
    background-color: #5e1313;
  }

  .MuiPopover-paper {
    overflow: visible !important;
  }
  th.top-row-no-padding{
    padding:0 !important;
  }

  .tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin-top: 100px;
    margin-left: 100px;
  }


  .tooltip .tooltiptext {
    visibility: hidden;
    width: 200px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -100px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .label{
    font-size: 12px;
  }
  .searchForm .form-control{
    font-size: 12.25px !important;
    color: rgb(118, 129, 146) !important;
  }
  .searchGloballyText{
    font-size: 12.25px !important;
    color: rgb(118, 129, 146) !important;
  }
  .searchForm input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border: 2px solid red; /* Border color */
    /* accent-color: green; */
  }


  .header .breadcum-wrapper {
    display: flex;
    align-items: center;

  }

  .breadcrumb {
    margin: 0;
    padding: 0;
  }

  .btn{
    font-family: sans-serif;
    font-size: 12.25px;
    font-weight: 400;
    line-height: 18.375px;
    color: rgb(255, 255, 255);
  }

  .btn-default{
    background-color: #e0e1e2 !important;
    color: rgb(0, 0, 21) !important;
  }
  .btn-default:hover{
    background-color: #cacbcd !important;
  }

  .btn-success{
    background-color: #21ba45 !important;
  }
  .btn-success:hover{
    background-color: #16ab39 !important;
  }

  .dropdown-toggle.btn-info{
    background-color: #39f !important;
    border-color: #39f !important;
  }

  .dropdown-toggle.btn-info:hover{
    background-color: #0d86ff !important;
    border-color: #0d86ff !important;
    color:#fff;
  }

  .btn-outline-danger{
    color: #ff0000;
  }
  .btn-outline-primary{
    color: #321fdb;
  }


  .ui.button.btn{
    font-family: sans-serif !important;
    font-size: 12.25px !important;
    font-weight: 400 !important;
    line-height: 18.375px !important;
    color: rgb(255, 255, 255) !important;
  }

  .ui.button.btn.btn-info{
    background-color: #39f !important;
    border-color: #39f !important;
  }

  .ui.button.btn.btn-info:hover{
    background-color: #0d86ff !important;
    border-color: #0d86ff !important;
    color:#fff;
  }

  p{
    margin-bottom: 0 !important;
  }

  .text-primary-label{
    font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    color: rgb(50, 31, 219);
  }
  .text-primary-value{
    font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: rgb(60, 75, 100)
  }

  .tab-text-primary-label{
    font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    color: rgb(0, 0, 21);
  }
  .tab-text-primary-value{
    font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: rgb(60, 75, 100)
  }

  table.payment-poc-summary{
    padding: 0 !important;
  }

  .table.payment-poc-summary > * > * {
    padding: o !important;
  }

  .payment-poc-summary > :not(caption) > * > *{
    padding: 0 !important;
  }

  .add-item-in-order > :not(caption) > * > *{
    background-color: transparent;
  }

  @media (min-width: 576px) {
    .fullScreenModal .modal-dialog {
        max-width: 100%;
        margin: 1.75rem auto;
    }
}


